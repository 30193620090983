<template>
  <div class="bg-white rounded-xl">
    <div class=" divide-y divide-gray-200">
      <div class="w-full p-4 space-y-4">
        <div>
          <p class="mb-4 font-semibold text-base-500">{{user.first_name}} {{user.last_name}}</p>
          <label for="photo" class="block text-sm font-medium text-gray-700"> {{loc.profile_img}} </label>
          <div class="mt-1 flex items-center">
          <span class="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
            <img v-if="user.profile_picture" :src="user.profile_picture" class="w-full h-full">
            <svg v-else class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
            <button @click="$refs.profile_pic.click()" type="button" class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base-500">
              {{loc.change_str}}
            </button>
            <input @change="upload_profile_pic($event)" hidden type="file" ref="profile_pic"/>
          </div>
        </div>

        <p class="block text-sm font-medium text-gray-700"> {{loc.current_balance}}: <span class="text-base-600">{{ user.balance }}</span> </p>
        <p class="block text-sm font-medium text-gray-700"> {{loc.benefits_chosen}}: <span class="text-base-600">{{user.chosen_benefits}}</span> </p>
        <p class="block text-sm font-medium text-gray-700"> {{loc.spent}}: <span class="text-base-600">{{user.spent}}</span> </p>
        <p class="block text-sm font-medium text-gray-700"> {{loc.base_balance}}: <span class="text-base-600">{{user.base_balance}}</span> </p>

        <div v-if="$store.state.auth.user.category_budgets.length" class="pt-4">
          <p class="text-left pl-4 font-semibold">{{loc.category_budgets}}:</p>
          <div v-for="b in $store.state.auth.user.category_budgets"
               class="border-t ">
            <p class="text-left pl-4">{{loc.category}}: {{b.category}}</p>
            <p class="text-left pl-4">{{loc.yearly_total_budget}}: {{b.amount}}</p>
            <p class="text-left pl-4">{{loc.current_balance}}: {{b.amount - b.spent}}</p>
            <p class="text-left pl-4">{{loc.spent}}: {{b.spent}}</p>
          </div>
        </div>
      </div>



      <div class="w-full p-4 space-y-4">

          <div class="mt-1 flex items-center">
          <span class="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
            <img v-if="user.company.picture" :src="user.company.picture" class="w-full h-full">

          </span>
          </div>

        <p class="block text-sm font-medium text-gray-700"> {{loc.company}}: <span class="text-base-600">{{user.company.name}}</span> </p>
        <p class="block text-sm font-medium text-gray-700"> {{loc.id_company}}: <span class="text-base-600">{{user.id_company}}</span> </p>
        <p class="block text-sm font-medium text-gray-700"> {{loc.position}}: <span class="text-base-600">{{user.position}}</span> </p>
<!--        <p class="block text-sm font-medium text-gray-700"> {{loc.first_name}}: <span class="text-base-600">{{user.first_name}}</span> </p>-->
<!--        <p class="block text-sm font-medium text-gray-700"> {{loc.last_name}}: <span class="text-base-600">{{user.last_name}}</span> </p>-->
        <p class="block text-sm font-medium text-gray-700"> {{loc.company_mail}}: <span class="text-base-600">{{user.email}}</span> </p>
        <p class="block text-sm font-medium text-gray-700"> {{loc.id_user}}: <span class="text-base-600">{{user.id}}</span> </p>
        <p class="block text-sm font-medium text-gray-700"> {{loc.phone_number}}: <span class="text-base-600">{{user.phone}}</span> </p>

        <div class="border rounded-xl p-2 space-y-4">
          <p class="block text-sm font-medium text-gray-700"> {{loc.change_password}}</p>

          <input v-model="current_password" type="password" class="shadow-sm focus:ring-base-500 focus:border-base-500 block w-full sm:text-sm border-gray-300 rounded-md" :placeholder="loc.current_password" />
          <input v-model="new_password" type="password" class="shadow-sm focus:ring-base-500 focus:border-base-500 block w-full sm:text-sm border-gray-300 rounded-md" :placeholder="loc.new_password" />
          <input v-model="repeat_password" type="password" class="shadow-sm focus:ring-base-500 focus:border-base-500 block w-full sm:text-sm border-gray-300 rounded-md" :placeholder="loc.repeat_password" />

          <button @click="reset_password" v-if="current_password && new_password && repeat_password && new_password == repeat_password" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-base-600 hover:bg-base-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base-500">
            {{loc.change_password}}
          </button>
        </div>

      </div>

      <div class="p-4 space-y-4">
        <div class="flex justify-start text-gray-600">
          <p class="text-xl font-bold">{{loc.total_rewards}}</p>
<!--          <div class="tooltip">-->
<!--            <InformationCircleIcon class="w-6 h-6 ml-2 text-base-500" />-->
<!--            <span class="tooltiptext">{{loc.rewards_text}}</span>-->
<!--          </div>-->
        </div>
        <Pie
            :chart-data="chartData"
        />

      </div>

    </div>
  </div>
</template>


<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import { Pie, Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)


import { Doughnut, mixins } from "vue-chartjs";

import {InformationCircleIcon} from "@heroicons/vue/solid";

export default {
  name: "Profile",
  data(){
    return {
      current_password: "",
      new_password: "",
      repeat_password: "",
      chartId: {
        type: String,
        default: 'bar-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 400
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Object,
        default: () => {}
      },
      chartOptions: {
        hoverBorderWidth: 20
      },
    }
  },
  components: {
    Pie,
    Bar,
    InformationCircleIcon
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user'
    }),
    chartData() {
      return {hoverBackgroundColor: "red",
      hoverBorderWidth: 10,
      labels: [this.loc.base_salary, this.loc.guaranteed_allowances, this.loc.annual_bonus, this.loc.benefits],
      datasets: [
        {
          label: "Data One",
          backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#ce821d"],
          data: [
            parseFloat(this.user.base_salary.replace(',', '')),
            parseFloat(this.user.guaranteed_allowances.replace(',', '')),
            parseFloat(this.user.annual_bonus.replace(',', '')),
            parseFloat(this.user.base_balance.replace(',', '')) + this.user.category_budgets.reduce((partialSum, a) => partialSum + parseFloat(a.amount), 0)
          ]
        }
      ]}
    }
  },
  methods: {
    ...mapMutations('ui', {
      set_error: 'set_error',
      set_success: 'set_success'
    }),
    ...mapActions('auth', ['fetchUser']),
    async upload_profile_pic(e) {
      let data = new FormData()
      data.append('profile_picture', e.target.files[0])
      await this.axios.patch('v2/me/', data)
      await this.fetchUser()
    },
    async reset_password() {
      try {
        let res = await this.axios.post('v2/change-password/', {
          old_password: this.current_password,
          new_password: this.new_password
        })

        this.set_success('Password changed')
      } catch (e) {
        console.log(e.response, "PPPP")
        this.set_error(e.response.data.Error)
      }

    }
  }
}
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.71);
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>